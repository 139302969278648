import EventPage from "../EventPage/EventPage"
import Footer from "../Footer/Footer"

function EventP() {
  return (
    <div>
      <EventPage/>
      <Footer/>
    </div>
  )
}

export default EventP
