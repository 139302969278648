import React from 'react'

import { FaWhatsapp } from "react-icons/fa";
import"./whatsap.css"

function Whatsappicon() {
  return (
    
    <>
      <div className="icon-bar1">
      
  <a target="_blank" href="https://wa.me/+918976256998?text=">
  
    
    
    <div className="what">
    <i className="fa fa-whatsapp callus" aria-hidden="true" ><FaWhatsapp /></i>
    </div>
   
  </a>
</div>

    </>


  )
}

export default Whatsappicon
