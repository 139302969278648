import React from 'react'
import ActivityP from '../ActivityP/ActivityP'
import Footer from '../Footer/Footer'

function ActivityPage() {
  return (
    <>
      <ActivityP/>
      <Footer/>
    </>
  )
}

export default ActivityPage
