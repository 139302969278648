import React from 'react'
import AbourP from '../AboutP/AbourP'
import Footer from '../Footer/Footer'

function AboutPages() {
  return (
    <>
      <AbourP/>
      <Footer/>
    </>
  )
}

export default AboutPages
