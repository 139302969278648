import React from 'react'
import GalleryP from '../Galleryp/GalleryP'
import Footer from '../Footer/Footer'

function GalleryPage() {
  return (
    <>
      <GalleryP/>
      <Footer/>
    </>
  )
}

export default GalleryPage
