import React from 'react'
import AccomodationP from '../AccomodationP/AccomodationP'
import Footer from '../Footer/Footer'

function AccomodationPage() {
  return (
    <>
      <AccomodationP/>
      <Footer/>
     
    </>
  )
}

export default AccomodationPage
