import React from 'react'
import ContectP from '../ContectP/ContectP'
import Footer from '../Footer/Footer'

function ContectPage() {
  return (
    <>
      <ContectP/>
      <Footer/>
    </>
  )
}

export default ContectPage
